<!--
 * @Author: your name
 * @Date: 2021-11-02 17:08:45
 * @LastEditTime: 2022-04-11 14:58:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \web-exam\src\views\login.vue
-->
<template>
  <div class="common-layout">
    <!-- <img alt="logo" class="logo" src="../assets/login_bg.png" /> -->
    <div class="login-img">
      <img src="../assets/login/login-left.png" alt="" />
    </div>
    <div class="login" v-if="active == 0">
      <h3 class="title" >登录 技能鉴定考试系统</h3>
      <el-form
        ref="loginForm"
        :model="loginForm"
        autocomplete="off"
        :rules="loginRules"
        label-position="left"
        label-width="0px"
        class="login-form"
      >
        <el-form-item prop="username" style="font-size: 18px !important">
          <el-input
            class="login-input"
            v-model.trim="loginForm.username"
            type="text"
            auto-complete="off"
            placeholder="用户名"
          >
          </el-input>
          <img class="input-img" src="../assets/login/user.png" alt="" />
        </el-form-item>
        <el-form-item prop="password" style="font-size: 18px !important">
          <!-- <input type="password" class="login-input" v-model.trim="loginForm.password" placeholder="请输入密码" @keyup.enter.native="handleLogin" /> -->
          <el-input
            class="login-input"
            v-model.trim="loginForm.password"
            type="password"
            auto-complete="off"
            placeholder="密码"
            @keyup.enter.native="handleLogin"
          >
          </el-input>
          <img class="input-img" src="../assets/login/password.png" alt="" />
        </el-form-item>
        <el-form-item style="width: 100%; margin-top: 30px; display: flex">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="
              width: 188px;
              height: 46px;
              font-size: 16px;
              background: #1fa3ff;
              border-radius: 25px;
              position: relative;
            "
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">登录</span>
            <span v-else>登 录 中...</span>
            <span
              v-if="!loading"
              style="
                width: 7px;
                height: 13px;
                color: #ffffff;
                position: absolute;
                right: 30px;
              "
              >＞</span
            >
          </el-button>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              color: #1fa3ff;
              margin-left: 20px;
              display: inline-block;
              cursor: pointer;
            "
            @click="goResetModal"
          >
            忘记密码?
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="login" v-if="active == 1">
      <div class="title" style="display: flex; align-items: center">
        <div @click="active = active - 1">
          <img
            style="width: 46px; height: 27px; margin-right: 18px"
            src="../assets/goBack.png"
            alt=""
          />
        </div>
        <div class="password-title">忘记密码</div>
      </div>
      <el-form
        ref="loginForm"
        autocomplete="off"
        :model="userInfo"
        label-position="left"
        label-width="0px"
        class="login-form"
      >
        <el-form-item style="font-size: 18px !important">
          <el-input
            class="login-input"
            v-model.trim="userInfo.userName"
            type="text"
            auto-complete="off"
            placeholder="请输入您的用户名"
            @keyup.enter.native="changeActive"
          >
          </el-input>
          <img class="input-img" src="../assets/login/user.png" alt="" />
        </el-form-item>
        <el-form-item style="width: 100%; margin-top: 30px; display: flex">
          <el-button
            :loading="loading"
            size="medium"
            type="primary"
            :disabled="btnDisabled"
            style="
              width: 188px;
              height: 46px;
              font-size: 16px;
              background: #1fa3ff;
              border-radius: 25px;
              position: relative;
            "
            @click.native.prevent="changeActive"
          >
            <span v-if="!loading">下一步</span>
            <span v-else>...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="active == 2" class="forget-modal">
      <div
        class="title"
        style="display: flex; align-items: center; width: 100%"
      >
        <div @click="active = active - 1">
          <img
            style="width: 46px; height: 27px; margin-right: 18px"
            src="../assets/goBack.png"
            alt=""
          />
        </div>
        <div class="password-title">找回密码</div>
      </div>

      <el-form
        style="margin-top: 20px"
        ref="loginForm"
        :model="userInfo"
        autocomplete="off"
        :rules="userInfoRules"
        label-position="left"
        label-width="0px"
        class="login-form"
      >
        <el-radio-group v-model="selectType" @change="select">
          <el-radio :label="2" style="font-size: 16px"
            >通过手机号码找回</el-radio
          >
          <el-radio :label="3" style="font-size: 16px"
            >通过电子邮箱找回</el-radio
          >
        </el-radio-group>
        <div class="isPhone" v-if="selectType == 2 && !loading">
          <div class="check-text">
            手机号码验证 <br />
            请填写完整的手机号码<span class="check-phone">{{ checkPhone }}</span
            >以验证身份
          </div>
          <el-form-item
            prop="phoneNumber"
            style="font-size: 18px !important; margin-bottom: 44px"
          >
            <el-input
              class="forget-input"
              v-model.trim="userInfo.phoneNumber"
              type="text"
              auto-complete="off"
              placeholder="请输入完整的手机号码"
            >
            </el-input>
            <span
              class="input-img"
              style="
                font-size: 18px;
                font-weight: 400;
                text-align: left;
                color: #1fa3ff;
                line-height: 32px;
              "
            >
              +86
            </span>
            <!-- <img class="" src="../assets/login/user.png" alt="" /> -->
          </el-form-item>
          <el-form-item
            prop="validateNum"
            style="font-size: 18px !important; margin-bottom: 50px"
          >
            <el-input
              class="forget-input-sm"
              v-model.trim="validateNum"
              type="text"
              auto-complete="off"
              placeholder="请输入短信验证码"
              @keyup.enter.native="changeActive"
            >
            </el-input>
            <div
              @click="getCode"
              :disabled="isDisabled"
              style="
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #000000;
                letter-spacing: 0.19px;
                margin-left: 20px;
                display: inline-block;
                cursor: pointer;
              "
            >
              {{ buttonName }}
            </div>
          </el-form-item>
        </div>
        <div class="isEmail" v-if="selectType == 3 && !loading">
          <div class="check-text">
            邮箱验证 <br />
            请填写完整的邮箱<span class="check-phone">{{ checkEmail }}</span
            >以验证身份
          </div>
          <el-form-item style="font-size: 18px !important; margin-bottom: 44px">
            <el-input
              class="forget-input-email"
              v-model.trim="userInfo.emailNumber"
              type="text"
              auto-complete="off"
              placeholder="请输入完整的邮箱号码"
              @keyup.enter.native="checkEmailCode"
            >
            </el-input>
            <!-- <img class="" src="../assets/login/user.png" alt="" /> -->
          </el-form-item>
          <el-form-item
            prop="validateNum"
            style="font-size: 18px !important; margin-bottom: 50px"
          >
            <el-input
              class="forget-input-sm"
              v-model.trim="validateNum"
              type="text"
              auto-complete="off"
              placeholder="请输入邮箱验证码"
            >
            </el-input>
            <div
              @click="getEmailCode"
              :disabled="isDisabled"
              style="
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: #000000;
                letter-spacing: 0.19px;
                margin-left: 20px;
                display: inline-block;
              "
            >
              {{ buttonName }}
            </div>
          </el-form-item>
        </div>
        <el-form-item style="width: 100%; margin-top: 30px; display: flex">
          <el-button
            v-if="selectType == 2"
            :loading="loading"
            size="medium"
            :disabled="btnDisabled"
            type="primary"
            style="
              width: 188px;
              height: 46px;
              font-size: 16px;
              background: #1fa3ff;
              border-radius: 25px;
              position: relative;
            "
            @click.native.prevent="changeActive"
          >
            <span v-if="!loading">下一步</span>
            <span v-else>...</span>
          </el-button>
          <el-button
            v-if="selectType == 3"
            :loading="loading"
            size="medium"
            :disabled="btnDisabled"
            type="primary"
            style="
              width: 188px;
              height: 46px;
              font-size: 16px;
              background: #1fa3ff;
              border-radius: 25px;
              position: relative;
            "
            @click.native.prevent="checkEmailCode"
          >
            <span v-if="!loading">下一步</span>
            <span v-else>...</span>
          </el-button>
          <span class="tips" v-if="selectType == 3"
            >邮箱号无法使用？请联系管理员</span
          >
          <span class="tips" v-else>手机号无法使用？请联系管理员</span>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="active == 3" class="forget-modal">
      <div
        class="title"
        style="display: flex; align-items: center; width: 100%"
      >
        <div @click="active = active - 1">
          <img
            style="width: 46px; height: 27px; margin-right: 18px"
            src="../assets/goBack.png"
            alt=""
          />
        </div>
        <div class="password-title">重置密码</div>
      </div>

      <el-form
        style="margin-top: 20px"
        ref="loginForm"
        :model="userInfo"
        autocomplete="off"
        label-position="left"
        label-width="0px"
        class="login-form"
      >
        <div class="check-text">
          身份验证成功 <br />
          请重置您的密码
        </div>
        <el-form-item style="font-size: 18px !important">
          <el-input
            class="forget-input"
            v-model.trim="userInfo.password"
            :type="PwdType"
            auto-complete="off"
            placeholder="请输入您的新密码"
          >
            <img
              style="
                position: absolute;
                width: 25px;
                top: 36px;
                right: 5px;
                transform: translateY(-12px);
              "
              @click="changePwdType('password')"
              slot="suffix"
              src="../assets/eye.png"
              alt=""
            />
          </el-input>
          <img class="input-img" src="../assets/login/password.png" alt="" />
        </el-form-item>
        <el-form-item style="font-size: 18px !important">
          <el-input
            class="forget-input"
            v-model.trim="userInfo.newpassword"
            :type="newPwdType"
            auto-complete="off"
            placeholder="请再次输入您的新密码"
            @keyup.enter.native="changeActive"
          >
            <img
              @click="changePwdType('newpassword')"
              style="
                position: absolute;
                width: 25px;
                top: 36px;
                right: 5px;
                transform: translateY(-12px);
              "
              slot="suffix"
              src="../assets/eye.png"
              alt=""
            />
          </el-input>
          <img class="input-img" src="../assets/login/password.png" alt="" />
        </el-form-item>

        <el-form-item style="width: 100%; margin-top: 30px; display: flex">
          <el-button
            :loading="loading"
            size="medium"
            :disabled="btnDisabled"
            type="primary"
            style="
              width: 188px;
              height: 46px;
              font-size: 16px;
              background: #1fa3ff;
              border-radius: 25px;
              position: relative;
            "
            @click.native.prevent="changeActive"
          >
            <span v-if="!loading">提交</span>
            <span v-else>...</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-button type="text" @click="showPuzzle = true"
      >点击打开 Dialog</el-button
    >

    <el-dialog
      title="提示"
      :visible.sync="showPuzzle"
      width="30%"
      :before-close="handleClose"
    >
      <span>这是一段信息</span>
    </el-dialog> -->
  </div>
</template>

<script>
import { instancePosts, getPermissions } from "../plugin/axios";

import userApi from "@/api/home.js";
// import signalr from '../plugin/signalr';
// import { SignalrUrlPrefix } from '../config/index';

// const signalR = require('@microsoft/signalr/dist/browser/signalr.js');

export default {
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能少于六位字符"));
      } else {
        callback();
      }
    };
    const validator = (rule, value, callback) => {
      if (value) {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!reg.test(value)) {
          this.isSave = false;
          console.warn("不通过");
          callback(new Error("请输入正确手机号"));

          return false;
        } else {
          console.warn("通过");
          this.isSave = true;
          return true;
        }
      } else {
        console.warn("不通过1");
        callback(new Error("请输入正确手机号"));

        this.isSave = false;
        return false;
      }
      // if (value.length < 6) {
      //   callback(new Error("密码不能少于六位字符"));
      // } else {
      //   callback();
      // }
    };
    return {
      PwdType: "password",
      newPwdType: "password",
      selectType: 2,
      showPuzzle: false,
      active: 0,
      checkPhone: "",
      checkEmail: "",
      CountDown: 60, //倒计时
      buttonName: "发送验证码", //按钮初始化文字
      isDisabled: false, //是否禁用按钮
      btnDisabled: false,
      isSave: false,

      validateNum: "",
      userInfo: {
        userName: "",
      },
      loginForm: {
        username: "",
        password: "",
        client_id: "basic-web",
        client_secret: "1q2w3e*",
        grant_type: "password",
        // client_id: config.client.client_id,
        // client_secret: config.client.client_secret,
        // grant_type: config.client.grant_type,
      },
      loginRules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      },
      userInfoRules: {
        phoneNumber: [
          { required: true, trigger: "blur", validator: validator },
        ],
      },
      loading: false,
      error: "",
      form: {},
    };
  },
  methods: {
    changePwdType(item) {
      console.warn(item);
      if (item == "password") {
        if (this.PwdType === "password") {
          this.PwdType = "text";
        } else {
          this.PwdType = "password";
        }
      }
      if (item == "newpassword") {
        if (this.newPwdType === "password") {
          this.newPwdType = "text";
        } else {
          this.newPwdType = "password";
        }
      }
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      done();
      // })
      // .catch((_) => {});
    },
    checkEmailCode() {
      this.btnDisabled = true;
      if (this.userInfo.emailNumber) {
        userApi
          .checkEmailDetail(
            this.userInfo.userName,
            this.userInfo.emailNumber,
            3
          )
          .then((res) => {
            console.warn(res.data.result);
            if (res.data.result) {
              userApi
                .checkEmailCode(this.userInfo.userName, this.validateNum)
                .then((result) => {
                  if (result.data.result) {
                    //   console.warn(res.data);
                    this.active += 1;
                  } else {
                    this.$message.error(result.data.msg);
                  }
                  this.btnDisabled = false;
                })
                .catch((err) => {
                  this.btnDisabled = false;
                });
            } else {
              this.btnDisabled = false;
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => {
            this.btnDisabled = false;
          });
      } else {
        this.btnDisabled = false;

        this.$message.error("请输入正确的邮箱号");
      }
    },
    goResetModal() {
      if (this.loginForm.username) {
        this.userInfo.userName = this.loginForm.username;
      }
      this.active = 1;
    },
    select(type) {
      this.loading = true;
      if (type == 2) {
        if (!this.checkPhone) {
          userApi
            .checkUserDetail(this.userInfo.userName, "", 1)
            .then((res) => {
              if (res.data.result) {
                console.warn(res.data);
                this.checkPhone = res.data.extendValue;
              } else {
                this.$message.error(res.data.msg);
                this.selectType = 3;
              }
              this.loading = false;
              this.btnDisabled = false;
            })
            .catch((err) => {
              this.btnDisabled = false;
              this.loading = false;
              console.warn(err);
            });
        } else {
          this.loading = false;
        }
      } else {
        if (!this.checkEmail) {
          console.warn("调获取邮箱接口");
          userApi
            .checkEmailDetail(this.userInfo.userName, "", 1)
            .then((res) => {
              if (res.data.result) {
                this.checkEmail = res.data.extendValue;
              } else {
                this.$message.error(res.data.msg);
                this.selectType = 2;
              }
              this.loading = false;
              console.warn(res);
            })
            .catch((err) => {
              this.loading = false;
              console.warn(err);
            });
        } else {
          this.loading = false;
        }
      }
      console.warn(type);
    },
    getCode() {
      if (this.isSave) {
        if (!this.isDisabled) {
          this.checkPhoneNumber();
        }
      } else {
        this.$message.error("请输入正确的手机号");
      }
    },
    getEmailCode() {
      if (this.userInfo.emailNumber) {
        if (!this.isDisabled) {
          this.checkEmailNumber();
        }
      } else {
        this.$message.error("请输入正确的邮箱号");
      }
    },
    checkPhoneNumber() {
      userApi
        .checkUserDetail(this.userInfo.userName, this.userInfo.phoneNumber, 2)
        .then((res) => {
          console.warn(res.data.result);
          if (res.data.result) {
            userApi
              .getCode(this.userInfo.userName)
              .then((result) => {
                this.isDisabled = true;
                let timerId = setInterval(() => {
                  this.CountDown--;
                  this.buttonName = this.CountDown + "秒后重试";
                  if (this.CountDown <= 0) {
                    clearInterval(timerId);
                    this.buttonName = "重新发送";
                    this.CountDown = 60;
                    this.isDisabled = false;
                  }
                }, 1000);
              })
              .catch((err) => {
                console.warn(err);
              });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {});
    },
    checkEmailNumber() {
      userApi
        .checkEmailDetail(this.userInfo.userName, this.userInfo.emailNumber, 3)
        .then((res) => {
          console.warn(res.data.result);
          if (res.data.result) {
            userApi
              .getEmailCode(this.userInfo.userName)
              .then((result) => {
                this.isDisabled = true;
                let timerId = setInterval(() => {
                  this.CountDown--;
                  this.buttonName = this.CountDown + "秒后重试";
                  if (this.CountDown <= 0) {
                    clearInterval(timerId);
                    this.buttonName = "重新发送";
                    this.CountDown = 60;
                    this.isDisabled = false;
                  }
                }, 1000);
              })
              .catch((err) => {
                console.warn(err);
              });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    },
    async changeActive() {
      this.btnDisabled = true;
      switch (this.active) {
        case 1:
          if (!this.userInfo.userName) {
            this.btnDisabled = false;
            this.$message.error("用户名不能为空");
            return;
          }
          let res = await userApi.checkUser(this.userInfo.userName);
          try {
            if (res.data.result) {
              if (res.data.mobileValue && res.data.emailValue) {
                this.checkPhone = res.data.mobileValue;
                this.checkEmail = res.data.emailValue;
                this.selectType = 2;
              } else if (res.data.mobileValue && !res.data.emailValue) {
                this.checkPhone = res.data.mobileValue;
                this.selectType = 2;
              } else {
                this.checkEmail = res.data.emailValue;
                this.selectType = 3;
              }
              this.active += 1;
              this.btnDisabled = false;
            } else {
              this.btnDisabled = false;
              this.$message.error(res.data.msg);
            }
          } catch (err) {
            this.btnDisabled = false;
            console.warn(err);
          }
          break;
        case 2:
          if (this.isSave) {
            if (this.validateNum) {
              userApi
                .checkUserDetail(
                  this.userInfo.userName,
                  this.userInfo.phoneNumber,
                  2
                )
                .then((checkRes) => {
                  if (!checkRes.data.result) {
                    this.$message.error(checkRes.data.msg);
                    this.btnDisabled = false;
                    return;
                  }
                  userApi
                    .checkCode(this.userInfo.userName, this.validateNum)
                    .then((result) => {
                      if (result.data.result) {
                        //   console.warn(res.data);
                        this.active += 1;
                      } else {
                        this.$message.error(result.data.msg);
                      }
                      this.btnDisabled = false;
                    })
                    .catch((err) => {
                      this.btnDisabled = false;
                    });
                })
                .catch((err) => {
                  this.btnDisabled = false;
                });
            } else {
              this.btnDisabled = false;
              this.$message.error("请输入验证码");
            }
          } else {
            this.btnDisabled = false;
            this.$message.error("请输入手机号及验证码");
          }

          break;
        case 3:
          if (this.userInfo.password && this.userInfo.newpassword) {
            if (this.userInfo.password == this.userInfo.newpassword) {
              console.warn("一致");
              userApi
                .checkPassWord(this.userInfo.password)
                .then((passwordRes) => {
                  console.warn(passwordRes);
                  if (passwordRes.data == "") {
                    this.save(this.selectType);
                  } else {
                    this.btnDisabled = false;
                    this.$message.error(passwordRes.data);
                  }
                })
                .catch((passwordErr) => {
                  this.btnDisabled = false;
                  console.warn(passwordErr);
                });
            } else {
              this.btnDisabled = false;
              this.$message.error("密码不一致");
            }
          } else {
            this.btnDisabled = false;
            this.$message.error("密码不能为空");
          }
      }
    },
    save(checkType) {
      let params = {
        //   userName: this.userInfo.userName,
        password: this.userInfo.password,
      };
      userApi
        .resetPassWord(
          checkType,
          this.userInfo.userName,
          this.validateNum,
          params
        )
        .then((res) => {
          console.warn(res);
          if (res.data) {
            this.$message.success("重置成功");
            this.btnDisabled = false;
            let time = setTimeout(() => {
              this.active = 0;
              this.userInfo = {};
              this.validateNum = "";
              clearTimeout(time);
            }, 2000);
          }
        })
        .catch((err) => {
          this.btnDisabled = false;
          if (err.response.data.error.message == "验证码已失效，请重新获取！") {
            this.active = 2;
          }
        });
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          // return new Promise((resolve, reject) => {
          instancePosts("/connect/token", this.loginForm)
            .then((response) => {
              sessionStorage.setItem("token", response.access_token);
              getPermissions("/api/abp/application-configuration")
                .then((res) => {
                  let arr = Object.keys(res.auth.grantedPolicies);
                  if (arr.length == 0) {
                    sessionStorage.setItem("auth", false);
                  } else {
                    sessionStorage.setItem("auth", true);
                  }
                  if (res.currentUser.id) {
                    sessionStorage.setItem("userId", res.currentUser.id);
                    userApi
                      .getUserInfo(res.currentUser.id)
                      .then((result) => {
                        sessionStorage.setItem(
                          "userInfo",
                          JSON.stringify(result.data)
                        );
                        if (!localStorage.getItem("examList")) {
                          localStorage.setItem("examList", []);
                        }
                        this.loading = false;
                        userApi.userCreate();
                        this.$router.push({
                          path: "/exam/list",
                        });
                      })
                      .catch((err) => {
                        console.warn(err);
                      });
                  }
                })
                .catch((err) => {
                  this.loading = false;

                  reject(error);
                });

              // resolve();
            })
            .catch((error) => {
              this.loading = false;

              // reject(error);
            });
          // });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
input:-internal-autofill-selected {
  transition: background-color 5000s ease-in-out 0s !important;
}
input:-webkit-autofill {
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}
::v-deep .el-form-item {
  border-image: linear-gradient(148deg, #ffffff 1%, #ffffff 90%) 2 2;
  background-image: linear-gradient(148deg, #ffffff 1%, #ffffff 90%) 2 2;
  border-radius: 41px;
}
::v-deep.el-form-item__content {
  display: flex !important;
}
::v-deep .el-input {
  width: 558px;
  height: 72px;
}
::v-deep .el-input__inner {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  // background-color: #f6fbfe !important;;
  background-image: linear-gradient(148deg, #ffffff 1%, #ffffff 90%) !important;
  font-size: 18px !important;
  border: 1px solid #ffffff;
  height: 72px;
  transition: background-color 5000s ease-in-out 0s !important;
  padding-left: 82px;
  border-radius: 41px;
}
.login-input {
  position: relative;
}
.input-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 38px;
}
.tips {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1fa3ff;
  margin-left: 20px;
  line-height: 32px;
  cursor: pointer;
}
.common-layout {
  background-color: #eef8fe;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/login/backougnd.png");
  background-size: cover;
  .login-img {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 15%;
    flex: 1;
    img {
      height: 50%;
      object-fit: cover;
    }
  }
  // background-image: url('../../assets/login_bg.png'); //todo
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    // vertical-align: top;
    // margin-right: 16px;
  }
  .password-title {
    font-size: 54px;
    font-weight: 700;
    text-align: left;
    color: #3c3c3c;
    line-height: 56px;
  }
  .forget-modal {
    padding: 16px;
    border-radius: 10px;
    width: 735px;
    margin: 0 auto;
    // right: 15%;
    flex: 1;

    // top: 53%;
    // -webkit-transform: translateY(-50%);
    // transform: translateY(-50%);
    margin: auto;
    margin-left: 16px;
    // height: 100px;
    // margin-right: 25%;
    position: relative;
    .title {
      width: 216px;
      height: 71px;
      font-size: 54px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      font-weight: 700;
      text-align: left;
      color: #3c3c3c;
      line-height: 56px;
    }
    .check-text {
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      color: #999999;
      line-height: 32px;
      margin: 40px 0 20px;
      .check-phone {
        color: #ff9f40;
      }
    }
    .forget-input {
      width: 413px !important;
      ::v-deep .el-input__inner {
        position: relative;
        width: 413px !important;
        border-radius: 13px !important;
      }
    }
    .forget-input-email {
      width: 413px !important;
      ::v-deep .el-input__inner {
        width: 413px !important;
        padding-left: 22px !important;
        border-radius: 13px !important;
      }
    }
    .forget-input-sm {
      width: 312px !important;
      ::v-deep .el-input__inner {
        width: 312px !important;
        padding-left: 22px !important;
        border-radius: 13px !important;
      }
    }
  }

  .login {
    padding: 16px;
    border-radius: 10px;
    width: 735px;
    margin: 0 auto;
    flex: 1;
    // right: 15%;
    // top: 53%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    // margin-left: 16px;
    margin: auto;
    height: 100px;
    // margin-right: 25%;
    position: relative;
    .title {
      position: absolute;
      top: -88px;
      width: 730px;
      font-size: 54px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #3c3c3c;
      line-height: 56px;
    }
    .icon {
      font-size: 24px;
      // color: @text-color-second;
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        // color: @primary-color;
      }
    }
  }
}
</style>
